import { Input } from '@chakra-ui/react'
import * as React from 'react'

export const SearchField = ({ handleUpdate }) => {

    const inputElement = React.useRef();

    React.useEffect(
        () => {
            if (!inputElement.current) return
            inputElement.current.focus();
        }
    )

    return (
        <Input
            ref={inputElement}
            size='lg'
            bgColor='white'
            placeholder='Saissez votre recherche'
            onChange={(e) => handleUpdate(e.target.value)}
        />
    )
}