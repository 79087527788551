import React, { useState } from 'react'

import {
    Box,
    Button,
    Center,
    Grid,
    Heading,
    HStack,
    Input,
    Link,
    SimpleGrid,
    Stack,
    Text
} from '@chakra-ui/react'
import LayoutPublic from '../components/layouts/layoutPublic'
import { SearchField } from '../components/Search/Field'
import { FormattedMessage } from 'react-intl'
import { BiLeftArrowAlt } from 'react-icons/bi'
import Debug from '../utils/Debug'
import AdCardLandscapeSmaller from '../components/ad/cardLandscapeSmaller'
import AdCardPortraitSmaller from '../components/ad/cardPortraitSmaller'
import { Link as GatsbyLink } from 'gatsby'
import Seo from '../components/Seo/';

import Helmet from 'react-helmet'

const contentful = require("contentful");

const client = contentful.createClient({
    space: process.env.GATSBY_CONTENTFUL_SPACE,
    environment: "master",
    accessToken: process.env.GATSBY_CONTENTFUL_TOKEN,
});

const SearchTpl = () => {

    const
        [search, setSearch] = useState(false),
        [query, setQuery] = useState(false),
        [ads, setAds] = useState(false),
        [models, setModels] = useState(false),
        [brands, setBrands] = useState(false),
        [univers, setUnivers] = useState(false);

    React.useEffect(
        () => {
            if (search.length < 2) return
            buildQuery(search)
        }, [search]
    )

    const buildQuery = (search) => {

        let baseQuery = {
            query: search,
            locale: process.env.GATSBY_LANG,
            limit: 50
        }

        client.getEntries({
            ...baseQuery
        })
            .then((response) => resultSorting(response.items))
            .catch(console.error)

    }

    const resultSorting = (items) => {
        let
            ads = [],
            models = [],
            brands = [],
            univers = [];

        for (let index = 0; index < items.length; index++) {
            const item = items[index];

            if (item.sys.contentType.sys.id === 'ad') {
                ads.push(item)
            }
            if (item.sys.contentType.sys.id === 'model' || item.sys.contentType.sys.id === 'modelVersion') {
                models.push(item)
            }
            if (item.sys.contentType.sys.id === 'brand') {
                brands.push(item)
            }
            if (item.sys.contentType.sys.id === 'univers') {
                univers.push(item)
            }


        }

        setAds(ads);
        setModels(models);
        setBrands(brands);
        setUnivers(univers);

    }

    const GroupHeader = ({ children, count }) => (
        <Heading
            as={HStack}
            fontSize={'md'}
            color='primary'
            display='flex'
        >
            <Text as='span'>
                {children} :
            </Text>
            <Center
                bgColor='primary'
                rounded='full'
                color='white'
                fontSize={'xs'}
                p={.5}
                minW='18px'
            >
                {count}
            </Center>
        </Heading>
    )

    return (
        <>
            <Seo
                title={process.env.GATSBY_LANG === 'fr' ? 'Recherchez le bateau de vos rêves sur Wanaboat' : 'Search for your dream boat on Wanaboat'}
                description={process.env.GATSBY_LANG === 'fr' ? 'Recherchez le bateau de vos rêves sur Wanaboat' : 'Search for your dream boat on Wanaboat'}
                slug='/'
            />
            <LayoutPublic>
                <Box
                    p={{ base: 6, lg: 0 }}
                >
                    <HStack>
                        <Button
                            leftIcon={<BiLeftArrowAlt />}
                            variant='outline'
                            size='lg'
                            bgColor='white'
                            as={GatsbyLink}
                            to='../'
                        >
                            <FormattedMessage id='main.back' />
                        </Button>

                        <SearchField
                            handleUpdate={(value) => setSearch(value)}
                        />
                    </HStack>

                    {process.env.GATSBY_LANG === 'fr' ?
                        <Text
                            color='gray.500'
                            py={2}
                            fontSize='sm'
                        >
                            Capitaine, voici le moteur de recherche, il est tout neuf et m'a demandé pas mal de boulot. Vous pouvez me faire part de vos remarques pour son amélioration ici : vhf@wanaboat.fr
                        </Text>
                        : null}

                    {ads && ads.length === 0 ?
                        <Center
                            p={10}
                        >
                            <Text>
                                🤔 {' '}Sniff, pas de résultat pour votre recherche Capitaine essayez autre chose ?
                            </Text>
                        </Center>
                        : null
                    }

                    <Grid
                        gridTemplateColumns={{
                            base: '100%',
                            lg: '40% 1fr 1fr 1fr'
                        }}
                        gap={10}
                        py={10}
                        opacity={ads.length || brands.length || models.length ? 1 : 0}
                        transition={`opacity 200ms`}
                    >


                        <Stack
                            spacing={4}
                        >
                            <GroupHeader count={ads.length}>
                                <FormattedMessage id="main.ads" />
                            </GroupHeader>
                            {ads && ads.length ? ads.map(ad =>
                                <>
                                    <AdCardLandscapeSmaller
                                        name={ad.fields.name}
                                        price={ad.fields.price}
                                        region={ad.fields.region}
                                        images={ad.fields.pictures}
                                        to={ad.fields.slug}
                                        isNavigable={true}
                                        ad={ad.fields}
                                        id={ad.sys.id}
                                        isNavigable={true}
                                    />
                                </>
                            ) :
                                null
                            }
                        </Stack>
                        <Stack
                            spacing={4}
                        >
                            <GroupHeader count={models.length}>
                                <FormattedMessage id="main.models" />
                            </GroupHeader>
                            {models ? models.map(item =>
                                <Link
                                    as={GatsbyLink}
                                    to={item.fields.slug}
                                    textDecor='underline'
                                >
                                    {item.fields.name}
                                </Link>
                            ) : null}
                        </Stack>

                        <Stack
                            spacing={4}
                        >
                            <GroupHeader count={brands.length}>
                                <FormattedMessage id='main.brands' />
                                {/* Marques & chantiers */}
                            </GroupHeader>
                            {brands ? brands.map(item =>
                                <Link
                                    as={GatsbyLink}
                                    to={item.fields.slug}
                                    textDecor='underline'
                                >
                                    {item.fields.name}
                                </Link>
                            ) : null}
                        </Stack>

                        <Stack
                            spacing={4}
                            opacity={univers.length ? 1 : 0}
                            transition='opacity 300ms'
                        >
                            <GroupHeader count={univers.length}>
                                <FormattedMessage id="main.univers" />
                            </GroupHeader>
                            {univers ? univers.map(item =>
                                <Link
                                    as={GatsbyLink}
                                    to={item.fields.slug}
                                    textDecor='underline'
                                >
                                    {item.fields.name}
                                </Link>
                            ) : null}
                        </Stack>

                    </Grid>

                </Box>
            </LayoutPublic>
        </>
    )
}

export default SearchTpl